<template>
    <div class="user-dashboard">
        <UserMenu/>
        <div class="main-panel">
            <TopNav/>
            <div class="content">
                <div class="card" v-if="asyncDone === false">
                    <div class="loader"></div>
                    <h4 class="text-center">{{asyncText}}</h4>
                </div>
                <section v-if="asyncDone === true">
                    <EmptyEmail v-if="!emailSetupFlag"/>
                    <Emails v-else/>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    import swal from "sweetalert2";
    import { db } from "@/firebase/init";
    export default {
        name: "Email",
        components: {
            UserMenu: () =>
                import(/* webpackChunkName: "userMenu" */ "../../../components/User/SideMenu.vue"),
            TopNav: () =>
                import(/* webpackChunkName: "topNav" */ "../../../components/TopNav.vue"),
            EmptyEmail: () =>
                import(/* webpackChunkName: "topNav" */ "../../../components/User/Email/EmptyEmail.vue"),
            Emails: () =>
                import(/* webpackChunkName: "topNav" */ "../../../components/User/Email/Emails.vue"),
        },
        created(){
            this.$store.dispatch("async/stateUpdate", { state: false });
            db.collection('users').doc(this.userUId).get()
                .then(doc => {
                    if(doc.data().emailSetupFlag){
                        this.$store.state.emailSetup = true;
                        this.$store.dispatch("async/stateUpdate", { state: true });
                    }else{
                        this.$store.dispatch("async/stateUpdate", { state: true });
                    }
                });
        },
        computed: {
            asyncDone() {
                return this.$store.getters["async/state"];
            },
            userUId() {
                return this.$store.getters.getUID;
            },
            emailSetupFlag(){
                return this.$store.state.emailSetup;
            },
            asyncText() {
                return this.$store.getters["async/text"];
            },
        },
    }
</script>

<style scoped>

</style>
